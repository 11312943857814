var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 mt-1 d-flex align-start",style:({ maxWidth: _vm.maxWidth + 'px' })},[_c('v-card',{staticClass:"rounded-lg",attrs:{"width":"100%","height":_vm.$vuetify.breakpoint.height - 100 + 'px',"loading":_vm.loading}},[_c('v-card-title',{staticClass:"primary white--text pa-1"},[_c('v-btn',{attrs:{"icon":"","dark":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('span',{staticClass:"ml-2"},[_vm._v("All Sessions")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pa-0 rounded-lg card-relative",style:({ height : _vm.$vuetify.breakpoint.height - 120 + 'px',overflowY : 'auto'})},[(_vm.session_data.length)?_c('v-list',{staticClass:"pa-0 rounded-lg"},[_vm._l((_vm.session_data),function(session){return [_c('v-list-item',{key:session.id,staticClass:"pr-0"},[_c('v-list-item-action',{staticStyle:{"min-width":"200px"}},[_c('span',[_vm._v(_vm._s(_vm.getTimeSlot(session)))])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-list-item-content',{staticClass:"pa-2"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"ml-2 d-flex align-center"},[(_vm.isUpcoming(session) || _vm.isInBetween(session))?_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","small":"","width":"85","color":"primary","to":'/app/session/' + session.session_id}},[_vm._v(" Join ")]):_vm._e(),(
                    _vm.isCompleted(session) &&
                    !session.pre_recorded_video
                  )?_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","small":"","width":"85","color":"primary","disabled":""}},[_vm._v(" Completed ")]):_vm._e(),(
                    _vm.isCompleted(session) && session.pre_recorded_video
                  )?_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","small":"","width":"85","color":"orange","dark":""},on:{"click":function($event){return _vm.openVideo(session.pre_recorded_video)}}},[_vm._v(" View ")]):_vm._e(),_c('p',{staticClass:"ml-4 mb-0"},[_vm._v(" "+_vm._s(session.title)+" ")])],1),_c('div',{staticClass:"d-flex align-center pt-1 justify-xl-space-between"},[(session.slide_file)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loadingUrlFor[session.session_id]},on:{"click":function($event){return _vm.downloadSessionSlide(session.session_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-download-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Slides")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loadingIcsDataFor[session.session_id],"disabled":_vm.isCompleted(session)},on:{"click":function($event){return _vm.downloadSessionIcsFile(session.session_id, session.title)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-clock")])],1)]}}],null,true)},[_c('span',[_vm._v("Add to Calendar")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('div',{staticClass:"session-description"},[_vm._v(_vm._s(session.description))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',_vm._g({staticClass:"action"},on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addToteBag(session.session_id)}}},[_c('v-icon',[_vm._v("mdi-briefcase-plus-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Add to My Tote Bag")])])],1)])])],1),_c('v-divider',{key:session.id})]})],2):_c('v-list',{staticClass:"pa-0 rounded-lg"},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" No sessions on "+_vm._s(_vm.moment(this.time).format("ddd, MMM Do"))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.show_preview),callback:function ($$v) {_vm.show_preview=$$v},expression:"show_preview"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2 rounded-lg px-0 py-0"},[(_vm.preview_data)?_c('video',{attrs:{"src":_vm.preview_data,"width":"100%","autoplay":"","controls":"","controlsList":"nodownload"}}):_vm._e()]),_c('v-btn',{staticStyle:{"position":"absolute","top":"0px","right":"10px"},attrs:{"color":"red","dark":"","fab":"","small":""},on:{"click":function($event){_vm.show_preview = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }